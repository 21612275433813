<template>
  <div class="examClass">
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        {{questionCateTitle}}
      </template>
    </van-nav-bar>

    <div class="question-subject-tab" v-if="subjectList.length>0">

      <van-tabs class="subject-tab" v-model:active="active" color="#1989fa" animated swipeable @click="clickTab" >
        <van-tab :title="item.abbreviation_name" :name="item.id" v-for="(item,index) in subjectList" :key="index">

          <v-touch v-on:swipeleft="getCurrentTest(1,index)"  v-on:swiperight="getCurrentTest(2,index)" :swipe-options="{ direction: 'horizontal'}" style="touch-action: pan-y!important;">
            <div v-for="(item2, index2) in testPaperList" :key="index2">
              <van-cell class="question-exam" center @click="getChildTestPaper(2,item2.id,index2)">

                <template #icon>
                  <van-icon class="left-icon" :name="icon_minus" v-if="item2.selectChapter" /> <!--加载下一级 已打开 @click="closedKey(index2)"-->
                  <van-icon class="left-icon" :name="icon_plus"  v-else /> <!--加载下一级 @click="getChildTestPaper(item2.id,index2)"-->
                </template>
                <template #title>
                  <span class="question-exam-chapter">{{ item2.title }}</span>
                </template>
                <template #label >
                  <div v-if="item2.showEdit">
                    <van-progress :percentage="item2.questionCount" stroke-width="8" style="width: 9rem;" class="question-progress" />
                    {{ item2.recordCount }}/{{ item2.count }}<span v-if="item2.correctRate !== false">，正确率{{item2.correctRate}}%</span>
                  </div>
                  <div v-else>
                    <span>共{{item2.testPaperCount}}套试卷</span>
                  </div>
                </template>
                <template #right-icon>
                  <div v-if="item2.showEdit">
                    <div v-if="(item2.status || item2.on_trial == 1) && item2.status2 == 1">
                      <van-tag v-if="item2.recordCount > 0" color="#1989fa" round plain size="medium" @click.stop="questionDetail(item2.questionId,item2.commodity_id)">重做</van-tag>
                      <van-icon v-else class="right-icon" color="#1989fa" name="edit" @click.stop="questionDetail(item2.questionId,item2.commodity_id)" />
                    </div>
                    <div v-else @click.stop="orderMsg(item2.id,item2.status2)">
                      <van-icon class="right-icon" name="lock"/>
                    </div>
                  </div>
                  <div v-else><van-icon name="arrow" size="2.6rem" /></div>
                </template>
              </van-cell>

              <!-- 第二级分类 -->
              <van-collapse class="question-exam-collapse" v-model="activeNames" v-for="(item3, index3) in item2.data" :key="index3" v-show="item2.selectChapter" @change="getChildTestPaper(3,item3.id,index2,index3)">
                <van-collapse-item :name="`${index2}`+`${index3}`">
                  <template #title>
                    <div class="question-exam-list">
                      <van-icon class="left-icon" :name="icon_minus" v-if="item3.selectChapter" /> <!--加载下一级 已打开  @click="closedKey(index2,index3)"-->
                      <van-icon class="left-icon" :name="icon_plus"  v-else /> <!--加载下一级-->
                      <p class="vertical-line"></p>
                      <span>
                      {{ item3.title }}
                      <p class="label" v-if="item3.showEdit">
                        <van-progress :percentage="item3.questionCount" stroke-width="8" style="width: 9rem;"
                                      class="question-progress" />
                        {{ item3.recordCount }}/{{ item3.count }}<span v-if="item3.correctRate !== false">，正确率{{item3.correctRate}}%</span>
                      </p>
                        <!-- <p class="label">共{{ item3.count }}题，{{ item3.answerCount }}人做过</p> -->
                    </span>
                    </div>
                  </template>
                  <template #right-icon v-if="item3.showEdit">
                    <div v-if="(item3.status || item3.on_trial == 1) && item3.status2 == 1">
                      <van-tag v-if="item3.recordCount > 0" color="#1989fa" round plain size="medium" @click.stop="questionDetail(item3.questionId,item3.commodity_id)">重做</van-tag>
                      <van-icon v-else class="right-icon" color="#1989fa" name="edit" @click.stop="questionDetail(item3.questionId,item3.commodity_id)" />
                    </div>
                    <div v-else @click.stop="orderMsg(item3.id,item3.status2)">
                      <van-icon class="right-icon" name="lock"/>
                    </div>
                  </template>




                  <!-- 第三级分类 -->
                  <div v-for="(item4, index4) in item3.data" :key="index4">
                    <van-cell class="question-exam-children" center :title="item4.title" v-if="item4.showEdit" @click.stop="questionDetail(item4.questionId,item4.commodity_id)">
                      <template #icon>
                        <van-icon class="left-icon" :name="icon_choice" />
                        <p class="vertical-line"></p>
                      </template>
                      <template #label>
                        <van-progress :percentage="item4.questionCount" stroke-width="8" style="width: 9rem;" class="question-progress" />
                        {{ item4.recordCount }}/{{ item4.count }}<span v-if="item4.correctRate !== false">，正确率{{item4.correctRate}}%</span>
                      </template>
                      <template #right-icon>
                        <div v-if="(item4.status || item4.on_trial == 1) && item4.status2 == 1">
                          <van-tag v-if="item4.recordCount > 0" color="#1989fa" round plain size="medium" @click.stop="questionDetail(item4.questionId,item4.commodity_id)">重做</van-tag>
                          <van-icon v-else class="right-icon" color="#1989fa" name="edit" @click.stop="questionDetail(item4.questionId,item4.commodity_id)" />
                        </div>
                        <div v-else @click.stop="orderMsg(item4.id,item4.status2)">
                          <van-icon class="right-icon" name="lock"/>
                        </div>
                      </template>
                    </van-cell>
                    <van-cell class="question-exam-children" center :title="item4.title" v-else @click.stop="orderMsg(item4.id,item4.status2)">
                      <template #icon>
                        <van-icon class="left-icon" :name="icon_choice" />
                        <p class="vertical-line"></p>
                      </template>
                      <template #label v-if="item4.showEdit">
                        <van-progress :percentage="item4.questionCount" stroke-width="8" style="width: 9rem;"
                                      class="question-progress" />
                        {{ item4.recordCount }}/{{ item4.count }}<span v-if="item4.correctRate !== false">，正确率{{item4.correctRate}}%</span>
                      </template>
                      <template #right-icon v-if="item4.showEdit">
                        <div v-if="(item4.status || item4.on_trial == 1) && item4.status2 == 1">
                          <van-tag v-if="item4.recordCount > 0" color="#1989fa" round plain size="medium" @click.stop="questionDetail(item4.questionId,item4.commodity_id)">重做</van-tag>
                          <van-icon v-else class="right-icon" color="#1989fa" name="edit" @click.stop="questionDetail(item4.questionId,item4.commodity_id)" />
                        </div>
                        <div v-else >
                          <van-icon class="right-icon" name="lock"/>
                        </div>
                      </template>
                    </van-cell>
                  </div>

                </van-collapse-item>
              </van-collapse>


            </div>
          </v-touch>

        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <van-empty
              description="暂无试卷"
              image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
              image-size="24rem">
        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">联系客服</van-button>
      </van-empty>
    </div>
    <!-- 弹出框（试卷状态） -->
    <van-popup v-model="showKS" class="question-content1" v-if="currentInfo.state === '0'">
        <div class="question-content-popup">
            <van-icon name="warning-o" color="#ee0a24" />
            <div>该试卷还在整理中，暂未上架，敬请期待哦~</div>
        </div>
    </van-popup>
    <!-- 弹出层（考试信息） -->
    <van-popup v-model="showKS" :style="{ height: '70%',width:'95%' }" v-if="currentInfo.state === '1'">
      <examKsxx :currentInfo="currentInfo" @closeFun="closeFun"></examKsxx>
    </van-popup>
  </div>

</template>

<script>
// 考试信息的弹框
import examKsxx from '@/modules/exam_ksxx'
import {testPaperDetail, testPaperKnowledgePointsList, testPaperSubjectList} from "@/request/api";
import {Toast, Loading} from "vant";
export default {
  name: 'QuestionListK',
  metaInfo: {
    title: '题库列表', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  components: { examKsxx },
  data() {
    return {
      activeNames: ['1'],
      active: '',
      item: '',
      selectChapter: true,
      selectPaper: false,
      icon_exam: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_exam.png",
      icon_plus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_plus.png",
      icon_minus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_minus.png",
      icon_choice: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_choice.png",
      show:false,
      showKS:false,
      msg:'',
      // 考试数据
      examObj: {},
      // 传给子组件的考当前试信息
      currentInfo: {},

      question_type_id: '',
      questionCateTitle: '题库',
      subject_id: '',
      subjectList: [],
      testPaperList: [],
      selectKey: '',
      selectKey2: '',
      appOrH5: false
    }
  },
  created() {
    this.question_type_id = this.$route.query.id;
    this.questionCateTitle = this.$route.query.title;
    this.appOrH5 = this.$route.query.appOrH5;
    // 获取考试列表
    // this.getData();
    this.getTestPaperTab();
  },

  methods: {
    mySchool(){
      this.$router.push('/MySchool')
    },
    // 点击考试信息的遮罩层
    closeFun() {
      this.showKS = false
    },

    onQuestionChapter() {
      if (this.selectChapter == true) {
        this.selectChapter = false
      } else if (this.selectChapter == false) {
        this.selectChapter = true
      }
    },
    getCurrentTest(val,index) {
      if(val == 1) {
        if(this.subjectList[index+1]) {
          // console.log('左',this.subjectList[index+1]);
          this.subject_id = this.subjectList[index+1].id;
          this.getTestPaper();
        }
      }
      if(val == 2) {
        if(this.subjectList[index-1]) {
          // console.log('右',this.subjectList[index-1]);
          this.subject_id = this.subjectList[index-1].id;
          this.getTestPaper();
        }
      }
    },
    ksxxFun(val){
      this.showKS = true
      this.currentInfo = val
    },

    toPaper(){
      // this.$router.push('/PaperDetail')
      this.$router.push('/Zxks')
    },
    getData() {
      this.examObj = [
        {
          title: "2018年中药一（历年真题）2018年中药一（历年真题）",
          examType:'1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore:120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType:[
            { text_ty:'2', textSub:'40', text_score:'40' },
            { text_ty:'6', textSub:'60', text_score:'60' },
            { text_ty:'5', textSub:'10', text_score:'10' },
            { text_ty:'3', textSub:'10', text_score:'10' }
          ],
        },
        {
          title: "2019年中药一（历年真题）",
          examType:'1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore:120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType:[
            { text_ty:'2', textSub:'40', text_score:'40' },
            { text_ty:'6', textSub:'60', text_score:'60' },
            { text_ty:'5', textSub:'10', text_score:'10' },
            { text_ty:'3', textSub:'10', text_score:'10' }
          ],
        },
        {
          title: "2020年中药一（历年真题）",
          examType:'1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore:120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: false,
          showBtn: true,
          textType:[
            { text_ty:'2', textSub:'40', text_score:'40' },
            { text_ty:'6', textSub:'60', text_score:'60' },
            { text_ty:'5', textSub:'10', text_score:'10' },
            { text_ty:'3', textSub:'10', text_score:'10' }
          ],
        },
        {
          title: "2021年中药一（历年真题）",
          examType:'1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '0', //考试的状态   0:暂未更新   1：可以做题
          totalScore:120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType:[
            { text_ty:'2', textSub:'40', text_score:'40' },
            { text_ty:'6', textSub:'60', text_score:'60' },
            { text_ty:'5', textSub:'10', text_score:'10' },
            { text_ty:'3', textSub:'10', text_score:'10' }
          ],
        },
      ]
    },

    onBack() {
      // history.back();
      if(window.android != undefined) {
        if(window.android.isapp() == true) {
          this.$router.push('/questionH5')
        } else {
          this.$router.push('/question')
        }
      } else {
        this.$router.push('/question')
      }
    },
    //获取科目列表
    getTestPaperTab() {
      let that = this;
      let question_type_id = that.question_type_id;
      testPaperSubjectList({type: question_type_id})
              .then(res => {
                if (res.data.code == 1) {
                  that.subjectList = res.data.data.data;
                  res.data.data.data.forEach(function (item, index) {
                    that.subject_id = item.id;
                    that.getTestPaper();
                    throw Error(123);
                  })
                }
              })
              .catch(err => {
                if(err !='Error: 123') {
                  Toast.fail('请求错误')
                }
                console.log("error");
                console.log(err);
              })
    },
    //获取试卷列表
    getTestPaper(pid = 0) {
      let that = this;
      let question_type_id = that.question_type_id;
      let subjectId = that.subject_id;
      testPaperKnowledgePointsList({type: question_type_id, subjectId,pid,level:1})
              .then(res => {
                if (res.data.code == 1) {
                  that.testPaperList = res.data.data.data;
                }
              })
              .catch(err => {
                Toast.fail('请求错误')
                console.log("error");
                console.log(err);
              })
    },
    clickTab(name, title) {
      console.log(name);
      this.subject_id = name
      this.getTestPaper();
    },
    //试卷详情
    questionDetail(id,commodity_id) {
      let that = this;
      testPaperDetail({test_paper_id: id})
              .then(res => {
                if (res.data.code == 1) {
                  that.showKS = true
                  that.currentInfo = res.data.data.data
                  that.currentInfo.commodity_id = commodity_id;

                  // console.log(that.currentInfo)
                  // console.log({
                  //     title: "2019年中药一（历年真题）",
                  //     examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
                  //     state: '1', //考试的状态   0:暂未更新   1：可以做题
                  //     totalScore: 120, //试卷总分
                  //     passScore: 72, //试卷及格分
                  //     textNum: 120, //试卷总题数
                  //     time: 120, //试卷总题数
                  //     userSum: 4059,
                  //     showEdit: true,
                  //     showBtn: false,
                  //     textType: [
                  //         {text_ty: '2', textSub: '40', text_score: '40'},
                  //         {text_ty: '6', textSub: '60', text_score: '60'},
                  //         {text_ty: '5', textSub: '10', text_score: '10'},
                  //         {text_ty: '3', textSub: '10', text_score: '10'}
                  //     ],
                  // })
                }
              })
              .catch(err => {
                Toast.fail('请求错误')
                console.log("error");
                console.log(err);
              })
    },
    //未购买 提示
    orderMsg(id,status) {
      if(status != 1) {
        Toast({
          message: '试卷未开放',
          icon: 'lock',
          position: 'top',
          duration: 1000,
        });
      } else {
        Toast({
          message: '题库未购买',
          icon: 'lock',
          position: 'top',
          duration: 1000,
        });
      }
    },
    //加载下一级
    getChildTestPaper(level,id,key,key2) {
      let that = this;
      let question_type_id = that.question_type_id;
      let subjectId = that.subject_id;
      if(key2 != undefined) {
        if(that.testPaperList[key]['data'][key2]['data'] != undefined && that.testPaperList[key]['data'][key2]['data'].length > 0) {
          // if(that.activeNames[0] == `${key}`+`${key2}`) {
          //   that.testPaperList[key]['data'][key2]['selectChapter'] = false;
          // } else {
          //   that.testPaperList[key]['data'][key2]['selectChapter'] = true;
          // }
          // that.activeNames.push(`${key}`+`${key2}`)
          // that.activeNames = [`${key}`+`${key2}`];

          //隐藏其他打开的弹窗，修改selectChapter为false
          // if(that.activeNames[0] != `${key}`+`${key2}`) {
          //   console.log('进入修改key'+'key2');
          //   that.testPaperList[that.selectKey]['data'][that.selectKey2]['selectChapter'] = false;
          // }
          // that.selectKey = key;
          // that.selectKey2 = key2;
          // console.log(that.selectKey,that.selectKey2);
          let selectKey = that.testPaperList[key]['data'][key2]['selectChapter'];
          that.testPaperList[key]['data'][key2]['selectChapter'] = !selectKey;
          return false;
        }
      } else {
        if(that.testPaperList[key]['data'] != undefined && that.testPaperList[key]['data'].length > 0) {
          that.testPaperList[key]['selectChapter'] = !that.testPaperList[key]['selectChapter'];
          return false;
        }
      }
      testPaperKnowledgePointsList({type: question_type_id, subjectId,pid:id,level})

              .then(res => {
                if (res.data.code == 1) {
                  if(key2 != undefined) {
                    that.testPaperList[key]['data'][key2]['data'] = res.data.data.data;
                    // that.testPaperList[key]['data'][key2]['selectChapter'] = true;
                    that.activeNames.push(`${key}`+`${key2}`)
                    // that.activeNames = [`${key}`+`${key2}`];
                    // if(that.activeNames[0] != `${key}`+`${key2}`) {
                    //   console.log('进入修改key'+'key2n');
                    //   that.testPaperList[that.selectKey]['data'][that.selectKey2]['selectChapter'] = false;
                    // }
                    // that.selectKey = key;
                    // that.selectKey2 = key2;
                    // console.log(that.selectKey,that.selectKey2);
                    that.testPaperList[key]['data'][key2]['selectChapter'] = !that.testPaperList[key]['data'][key2]['selectChapter'];
                    let questionIndex = that.testPaperList[key]['data'][key2];
                    if(res.data.data.data.length == 0 && questionIndex.showEdit) {
                      //如果当前知识点有题，没有下级数据，展示试卷
                      if((questionIndex.status || questionIndex.on_trial == 1) && questionIndex.status2 == 1) {
                        that.questionDetail(questionIndex.questionId,questionIndex.commodity_id);
                      } else {
                        that.orderMsg(questionIndex.questionId,questionIndex.status2);
                      }
                    }
                  } else {
                    that.testPaperList[key]['data'] = res.data.data.data;
                    that.testPaperList[key]['selectChapter'] = true;
                  }
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    //关闭
    closedKey(key,key2) {
      if(key2 != undefined) {
        this.testPaperList[key]['data'][key2]['selectChapter'] = !this.testPaperList[key]['data'][key2]['selectChapter'];
      } else {
        this.testPaperList[key]['selectChapter'] = !this.testPaperList[key]['selectChapter'];
      }
    },
    collClick(id,key,key2) {
      console.log(123);
    }
  },
  computed: {

  },
}
</script>
 
<style lang = "less">
#app {
  background-color: #ffffff;
}

.examClass .van-popup {
  border-radius: 1.5rem;
  overflow: hidden;
}

.question-subject-tab {
  padding: 12.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
}
.question-subject-tab .subject-tab .van-tabs__wrap {
  position: fixed;
  top: 6rem;
  left: 0;
  z-index: 99;
  width: 100%;
}

.question-subject-tab .subject-tab .van-tabs__line {
  bottom: 0.5rem !important;
  left: 0 !important;
  z-index: 1;
  width: 3.2rem !important;
  height: 0.5rem !important;
  border-radius: 2rem !important;
}

.question-subject-tab .subject-tab .van-tab .van-tab__text {
  padding: 0 0.6rem;
}

.question-subject-tab .subject-tab .van-tab--active .van-tab__text {
  font-size: 2.2rem !important;
}

// .question-subject-tab .subject-tab .van-tabs__content {
//   margin: 1rem 0;
// }

.question-exam {
  padding: 1.5rem 1rem !important;
}

.question-exam .left-icon01 {
  font-size: 4rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .right-icon {
  font-size: 3rem !important;
  // color: #1989fa;
  // padding: 0 1.3rem !important;
}

.question-exam .van-icon-lock {
  font-size: 3rem !important;
  color: #656466;
  // padding: 0 1.3rem !important;
}


.question-exam .van-cell__title {
  padding-right: 1rem;
}

.question-exam .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-child {
  margin-left: 3rem;
}

.question-child .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 2.3rem;
}

.question-progress {
  float: left;
  margin: 0.8rem 1rem 0 0 !important;
  display: flex;
  justify-content: center;
}

.question-progress .van-progress__pivot {
  display: none;
}

.question-exam-chapter {
  font-weight: 600 !important;
  font-size: 2.1rem;
}

.question-exam-collapse .van-collapse-item {
  padding: 0rem 1rem 0rem 3.5rem !important;
}

.question-exam-collapse .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.3rem;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title {
  align-items: center;
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title:active {
  background-color: #ffffff;
}

.question-exam-collapse .van-collapse-item .question-exam-list {
  // line-height: 6rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-list .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .van-icon-lock {
  font-size: 3rem !important;
  padding: 1.5rem 0rem !important;
  color: #656466;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .right-icon {
  font-size: 3rem !important;
  padding: 1.5rem 0rem !important;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-list .label {
  font-size: 1.6rem;
  color: #969799;
  padding-top: 1rem;
}

.question-exam-collapse .van-collapse-item .van-icon-arrow {
  font-size: 2.2rem;
  line-height: 6rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}


.question-exam-collapse::after {
  border-width: 0 !important;
}

.van-collapse-item__title::after {
  display: none !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children {
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-children .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.21rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children::after {
  border-width: 0 !important;
}

.question-content1 {
  border-radius: 1rem;
}

.question-content1 .question-content-popup {
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}

.question-content1 .question-content-popup .van-icon {
  padding: 1rem 1.5rem;
  font-size: 3.5rem;
  line-height: 3rem;
}
</style>




