<template>
  <div class="signin">
    <van-nav-bar fixed >
      <template #left >
        <div style="padding-left: 1rem;font-weight: 600;">
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(-1)">
            <van-icon name="minus" color="#333333" style="font-weight: 600;" />
          </van-tag>
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(0)">默认</van-tag>
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(1)">
            <van-icon name="plus" color="#333333" style="font-weight: 600;" />
          </van-tag>
        </div>
        
      </template>
      <template #right @click-right="userKefu">
          <!-- <van-tag plain color="#333333" text-color="#333333" size="medium">
            <van-icon name="down" color="#333333" style="font-weight: 600;" />
            下载
          </van-tag> -->
          <div  style="padding-right: 1rem;">
            <van-button icon="down" type="default" size="mini" >下载</van-button>
          </div>
      </template>
    </van-nav-bar>
    <div class="content1">
      <div class="handoutBox" v-for="(item, index) in handoutItem" :key="index">
        <van-cell class="content-top">
          <template #title>
            <div v-html="item.content" class="custom-title-box" :style="{ fontSize: fontSize1 + 'rem',lineHeight:fontSize1*2 + 'rem' }"></div>
            <div class="custom-title-question">
              <!-- <van-cell class="comRadio_top" title="综合分析题" ></van-cell> -->
              <div class="comData_title">
                <div>
                  <div class="stem">
                    <span class="data_value">某男，43岁。症见胁肋疼痛、胸闷痞满、食滞不清、呕吐酸水，脉弦。医师处以中成药柴胡舒肝丸，该蜜丸处方由茯苓、麸炒枳壳、豆蔻、酒白芍、甘草、醋香附、陈皮、桔梗、姜厚朴、炒山楂、防风、六神曲（炒)、柴胡、黄芩、薄荷、紫苏梗、木香、炒槟榔、醋三棱、酒大黄、青皮（炒)、当归、姜半夏、乌药、醋莪术组成。</span>
                    <div></div>
                  </div> 
                  110. 
                  <span style="margin-left: 0.2rem;">
                    <span class="typeTest">(单选)</span>
                    <span>本品显微镜检查，可见韧皮纤维淡黄色，梭形，壁厚，孔沟细。处方中具有该特征的饮片是（  ）。</span>
                  </span>
                  <div></div>
                  <span class="comData_scoreClass">（1分）</span>
                  <div class="comRadio_option">
                    <div class="inputClass">
                      <span class="option_key">A</span>
                      <span class="option_value"> 酒大黄 </span>
                    </div>
                    <div class="inputClass">
                      <span class="option_key">B</span>
                      <span class="option_value"> 黄芩 </span>
                    </div>
                    <div class="inputClass">
                      <span class="option_key">C</span>
                      <span class="option_value"> 茯苓 </span>
                    </div>
                    <div class="inputClass">
                      <span class="option_key">D</span>
                      <span class="option_value"> 炒槟榔 </span>
                    </div>
                    <div class="inputClass">
                      <span class="option_key">E</span>
                      <span class="option_value"> 甘草 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell center class="bottom">
          <template #title>
            <div style="display: flex;">
              <van-button type="default" size="small" block>
                <van-icon name="play-circle-o" color="#1989fa" style="padding-right: 0.5rem;" />播放/暂停
              </van-button>
              <van-button type="default" size="small" block>
                <van-icon name="edit" color="#1989fa" style="padding-right: 0.5rem;" />添加笔记
              </van-button>
            </div>
          </template>
        </van-cell>

      </div>

    </div>

  </div>
</template>

<script>

export default {
  data() {

    return {
      item: '',
      fontSize1: 2,
      checked: false,
      handoutItem: [
        {id:1,content:'<p style="text-indent: 24pt; text-align: left;"><strong>第一条 辅导内容及方式</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.1 甲方经慎重考虑选择参加乙方组织的</span><u>2024年社会工作者网络辅导-不过重学班</u><span style="font-family: 宋体;">课程学习，课程包含：导学班+精讲班+强化班+模考班+点睛班+应收技巧班；</span></p><p style="text-indent: 24.1pt;"><strong>辅导科目、费用</strong></p><p style="text-indent: 24.1pt;"><br></p><table style="width: 100%;"><tbody><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">科目名称</td><td colSpan="1" rowSpan="1" width="148">班次</td><td colSpan="1" rowSpan="1" width="148">价位</td></tr><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">社会工作者-初级</td><td colSpan="1" rowSpan="1" width="148">不过重学班</td><td colSpan="1" rowSpan="1" width="148">*元/全科</td></tr><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">社会工作者-中级</td><td colSpan="1" rowSpan="1" width="148">不过重学班</td><td colSpan="1" rowSpan="1" width="148">*元/全科</td></tr></tbody></table><p style="text-indent: 24pt; text-align: left;"><strong>2024年未通过学员，免费重读2025年同班期网络课程。</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.2 为确保辅导效果，在本协议约定的学习期间内，乙方可适当调整或延长课时，如延长辅导时间，乙方不另外收取费用。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.3 甲方未经过乙方同意，个人原因放弃考试的，视为甲方通过考试，不享受第二年免费重读服务。</span></p><p><br></p>'},
        {id:2,content:'<p style="text-indent: 24pt; text-align: left;"><strong>第六条 </strong><span style="font-family: 宋体;">争议的解决</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.1 本协议的内容、执行和解释及争议的解决均应适用中国法律。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.2 双方在履行本协议的过程中，如发生任何争议，则双方应首先进行友好协商解决。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.3 如果某一争议未在一方首次提出协商之日后 30 日内通过友好协商解决，则任何一方可将该争议提交北京仲裁委员会申请仲裁。</span></p><p style="text-indent: 24pt; text-align: left;"><strong>第七条 附则</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.1 本协议一式二份，甲乙双方各执一份；如有未尽事宜，由双方协商作出补充协议。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.2 本协议自甲乙双方签字、盖章之日起生效；</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.3合同经双方协商一致可解除。 </span></p>'}
      ],

    };

  },
  methods: {
			onBack() {
				history.back();
			},
			userKefu(){
				this.$router.push({ path: '/user' });
    },
    onFontSize(index) {
      if (index == -1) {
        if (this.fontSize1 > 1.5) {
          this.fontSize1 = this.fontSize1 - 0.4
        }
      } else if (index == 0) {
        this.fontSize1 = 2
      } else { 
        if (this.fontSize1 < 3) {
          this.fontSize1 = this.fontSize1 + 0.4
          console.log(this.fontSize1)
        }

      }
    },
      
	}

};

</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.signin {
  // background-color: #ffffff;
  // 
  text-align: center;
  color: #666666;
  /* background-image: url('http://cdnoss.ksyt.com.cn/wxImages/2022yaoshi_yuekao.jpg'); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f5f5f5;
}

.signin .van-nav-bar {
  // background: #1989fa;
  z-index: 999999;
}

.signin .van-hairline--bottom::after {
  border-bottom-width: 0;
}

.signin .van-nav-bar .van-icon {
  color: #ffffff;
  font-size: 2rem !important;
}

.signin .van-nav-bar__title {
  color: #ffffff;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }s

.content1 {
  padding: 8rem 1.3rem 1.3rem 1.3rem;
}

.handoutBox {
  margin-bottom: 1rem
}

.content-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1.5rem;
}

.content-top .custom-title-box {
  text-align: left;
  display: block;
}


.bottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: 6rem;
  padding: 1rem;
}

.bottom .van-cell__title .custom-title b {
  font-weight: 600;
  font-size: 2.3rem;
  padding: 0 0.8rem;
  color: #fb4d19;
}

strong {font-weight: bold;}
table {border: 0.1rem solid #333333;border-radius: 1rem;}
table tr,table td {border: 0.1rem solid #333333;}


.comRadio_top[data-v-a862932a] {
    height: 3rem !important;
    /* border-bottom: 0.1rem solid #f7f7f7; */
    position: fixed;
    left: 2rem;
    padding-right: 4rem;
    z-index: 99;
}

.comData_title {
    width: auto;
    text-align: left;
    padding: 1rem 0;
    font-size: 2.2rem;
    line-height: 3.2rem;
}
.comData_title .stem {
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.typeTest {
    color: #4ba5ff;
}
.comData_scoreClass {
    color: #a0a0a0;
}
.comData_title .comRadio_option {
    margin: 1rem 0rem;
}
.comData_title .inputClass {
    position: relative;
    height: auto;
    padding: 1rem;
    /* margin-left: 15px; */
    text-align: left;
    // margin-top: 1.5rem;
    background: #f6f7f9;
    border: 0.1rem solid #f6f7f9;
    border-radius: 0.5rem;
    font-size: 2rem;
    line-height: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.comData_title .inputClass .option_key {
    border: 0.1rem solid #cccccc;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    padding: 0.4rem;
    position: absolute;
    left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* display: none; */
}
.comData_title .inputClass .option_value {
    padding-left: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}


</style>
